import { configureStore } from '@reduxjs/toolkit';

import initialParam from './initialParam';
import appApi from './appApi';
import modal from './modal';

export const store = configureStore({
  reducer: {
    initialParam,
    appApi,
    modal,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
