import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setInitialParamToken } from 'src/store/initialParam';

export const useInitialParam = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const token = searchParams.get('token') || localStorage.getItem('token');
    if (token) {
      localStorage.setItem('token', token);
      dispatch(setInitialParamToken(token));
    }
  }, [dispatch, searchParams]);
};
