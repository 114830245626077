const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let error = null;
  try {
    document.execCommand('copy');
  } catch (err) {
    error = err;
  }

  document.body.removeChild(textArea);
  return error;
};
export const copyTextToClipboard = (text: string) => {
  return new Promise((resolve) => {
    return resolve(fallbackCopyTextToClipboard(text));
    // if (!navigator.clipboard) {
    //   return resolve(fallbackCopyTextToClipboard(text));
    // }
    // navigator.clipboard.writeText(text).then(
    //   function () {
    //     return resolve(true);
    //   },
    //   function (err) {
    //     return reject(err);
    //   },
    // );
  });
};

export const formatPoint = (point: number, n: number = 0, x: number = 0) => {
  const regex = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return point.toFixed(Math.max(0, ~~n)).replace(new RegExp(regex, 'g'), '$&,');
};
