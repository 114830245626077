import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type {
  AppApiUserMeResponseDto,
  AppApiUserMyPointResponseDto,
  AppApiUserListReferredsResponseDto,
  AppApiStatisticPointResponseDto,
} from 'src/types/appApi';

export interface AppApiState {
  userMe: AppApiUserMeResponseDto | null;
  userMyPoint: AppApiUserMyPointResponseDto | null;
  userListReferrals: AppApiUserListReferredsResponseDto | null;
  statisticPoint: AppApiStatisticPointResponseDto | null;
}

const initialState: AppApiState = {
  userMe: null,
  userMyPoint: null,
  userListReferrals: null,
  statisticPoint: null,
};

export const appApiSlice = createSlice({
  name: 'appApi',
  initialState,
  reducers: {
    setAppApiUserMe: (state, action: PayloadAction<AppApiUserMeResponseDto | null>) => {
      state.userMe = action.payload;
    },
    setAppApiUserMyPoint: (state, action: PayloadAction<AppApiUserMyPointResponseDto | null>) => {
      state.userMyPoint = action.payload;
    },
    setAppApiUserListReferrals: (
      state,
      action: PayloadAction<AppApiUserListReferredsResponseDto | null>,
    ) => {
      state.userListReferrals = action.payload;
    },
    setAppApiStatisticPoint: (
      state,
      action: PayloadAction<AppApiStatisticPointResponseDto | null>,
    ) => {
      state.statisticPoint = action.payload;
    },
  },
});

export const {
  setAppApiUserMe,
  setAppApiUserMyPoint,
  setAppApiUserListReferrals,
  setAppApiStatisticPoint,
} = appApiSlice.actions;

const appApiReducer = appApiSlice.reducer;
export default appApiReducer;
