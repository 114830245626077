import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface InitialParamState {
  token: string;
}

const initialState: InitialParamState = {
  token: '',
};

export const initialParamSlice = createSlice({
  name: 'initialParam',
  initialState,
  reducers: {
    setInitialParamToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { setInitialParamToken } = initialParamSlice.actions;

const initialParamReducer = initialParamSlice.reducer;
export default initialParamReducer;
