import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ModalState {
  inviteFrens: {
    show: boolean;
  };
}

const initialState: ModalState = {
  inviteFrens: {
    show: false,
  },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalInviteFrensShow: (state, action: PayloadAction<boolean>) => {
      state.inviteFrens.show = action.payload;
    },
  },
});

export const { setModalInviteFrensShow } = modalSlice.actions;

const modalReducer = modalSlice.reducer;
export default modalReducer;
