import React, { createContext, ReactChild } from 'react';

const appConfig = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  botUrl: process.env.REACT_APP_BOT_URL || '',
};
export const AppConfigContext = createContext(appConfig);

interface IAppConfigProvider {
  children?: ReactChild;
}
export const AppConfigProvider: React.FC<IAppConfigProvider> = (props) => (
  <AppConfigContext.Provider value={appConfig}>{props.children}</AppConfigContext.Provider>
);
