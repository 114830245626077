import React, { useContext, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/store';
import { setModalInviteFrensShow } from 'src/store/modal';

import { AppConfigContext } from 'src/providers/appConfig';

import { useAppApi } from 'src/hooks/useAppApi';

import ComponentCommonSpinner from 'src/components/common/spinner';

import { copyTextToClipboard } from 'src/utilities/helper';

const ModalInviteFrens: React.FC = () => {
  const dispatch = useDispatch();

  const appConfig = useContext(AppConfigContext);
  const userMe = useSelector((state: RootState) => state.appApi.userMe);
  const modalInviteFrens = useSelector((state: RootState) => state.modal.inviteFrens);

  const [modalTop, setModalTop] = useState('top-[100%]');
  useEffect(() => {
    if (modalInviteFrens.show) {
      setModalTop('top-0');
    } else {
      setModalTop('top-[100%]');
    }
  }, [modalInviteFrens.show]);

  const onShouldClose = useCallback(() => {
    dispatch(setModalInviteFrensShow(false));
  }, [dispatch]);

  const getInviteLink = useCallback(() => {
    if (!userMe) {
      return '';
    }
    return `${appConfig.botUrl}?start=${btoa(`referrerCode=${userMe.user.code}`)}`;
  }, [appConfig, userMe]);

  const [isCopied, setIsCopied] = useState(false);
  const onCopyInviteLinkClicked = useCallback(() => {
    if (isCopied) {
      return;
    }
    setIsCopied(true);
    try {
      const inviteLink = getInviteLink();
      if (inviteLink) copyTextToClipboard(inviteLink);
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      setIsCopied(false);
      onShouldClose();
    }, 1000);
  }, [getInviteLink, onShouldClose, isCopied]);

  const { appApiQuoteGetRandom } = useAppApi();
  const [isSharing, setIsSharing] = useState(false);
  const isInviteLinkClicked = useCallback(async () => {
    setIsSharing(true);

    try {
      const quoteGetRandom = await appApiQuoteGetRandom();

      const inviteLink = encodeURIComponent(getInviteLink());
      const inviteText = encodeURIComponent(
        `${quoteGetRandom?.quote.content} - ${quoteGetRandom?.quote.author}`,
      );
      // @ts-expect-error no Telegram type
      window.Telegram.WebApp.openTelegramLink(
        `https://t.me/share/url?url=${inviteLink}&text=${inviteText}`,
      );
      // window.open(`https://t.me/share/url?url=${inviteLink}&text=${inviteText}`, '_blank')?.focus();
    } catch (error) {
      console.error(error);
    }

    setIsSharing(false);
    onShouldClose();
  }, [appApiQuoteGetRandom, getInviteLink, onShouldClose]);

  const modalDisabled = isCopied || isSharing;

  return (
    <>
      {modalInviteFrens.show && (
        <div
          className={`absolute w-full h-full right-0 bottom-0 left-0 z-[1] transition-all ${modalTop}`}
          onClick={() => {
            if (!modalDisabled) onShouldClose();
          }}
        >
          <div
            className="absolute right-0 bottom-0 left-0 z-[2] bg-zinc-800 rounded-t-lg"
            onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}
          >
            <div className="px-5 py-5 relative">
              <p className="text-white text-lg text-extrabold text-center">Invite Frens</p>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 disabled:bg-transparent disabled:text-gray-400 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center absolute top-5 right-5"
                disabled={modalDisabled}
                onClick={() => {
                  if (!modalDisabled) onShouldClose();
                }}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <hr className="h-[2px] mx-5 bg-gray-200 border-0 dark:bg-zinc-400"></hr>
            <div className="px-5 py-5">
              <button
                type="button"
                className="w-full text-black bg-white hover:bg-gray-200 active:bg-gray-400 disabled:bg-white font-semibold rounded-lg text-lg px-5 py-2.5"
                disabled={modalDisabled}
                onClick={onCopyInviteLinkClicked}
              >
                {isCopied ? 'Copied' : 'Copy invite link'}
              </button>
              <button
                type="button"
                className="w-full text-black bg-white hover:bg-gray-200 active:bg-gray-400 disabled:bg-white font-semibold rounded-lg text-lg px-5 py-2.5 mt-3 flex justify-center items-center"
                disabled={modalDisabled}
                onClick={isInviteLinkClicked}
              >
                <span>{'Share invite link'}</span>
                {isSharing && (
                  <div className="w-4 h-4 ml-3">
                    <ComponentCommonSpinner isDark />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalInviteFrens;
