import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  setAppApiUserMe,
  setAppApiUserMyPoint,
  setAppApiUserListReferrals,
  setAppApiStatisticPoint,
} from './store/appApi';

import { useInitialParam } from './hooks/useInitialParam';
import { useAppApi } from 'src/hooks/useAppApi';

import Router from './screens';
import ModalInviteFrens from './modals/inviteFrens';

const App: React.FC = () => {
  const dispatch = useDispatch();

  useInitialParam();

  const { appApiUserMe, appApiUserMyPoint, appApiUserListReferreds, appApiStatisticPoint } =
    useAppApi();
  useEffect(() => {
    appApiUserMe()
      .then((data) => {
        dispatch(setAppApiUserMe(data));
      })
      .catch(() => {});
  }, [dispatch, appApiUserMe]);
  useEffect(() => {
    appApiUserMyPoint()
      .then((data) => {
        dispatch(setAppApiUserMyPoint(data));
      })
      .catch(() => {});
  }, [dispatch, appApiUserMyPoint]);
  useEffect(() => {
    appApiUserListReferreds()
      .then((data) => {
        dispatch(setAppApiUserListReferrals(data));
      })
      .catch(() => {});
  }, [dispatch, appApiUserListReferreds]);
  useEffect(() => {
    appApiStatisticPoint()
      .then((data) => {
        dispatch(setAppApiStatisticPoint(data));
      })
      .catch(() => {});
  }, [dispatch, appApiStatisticPoint]);

  return (
    <>
      <div className="flex flex-col items-center h-full select-none">
        <div className="w-[500px] max-w-full h-full py-10 px-5">
          <div className="h-full relative">
            <Router />
            <ModalInviteFrens />
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
