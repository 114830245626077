import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AppConfigProvider } from './providers/appConfig';

import { store } from './store';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <AppConfigProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppConfigProvider>
    </ReduxProvider>
  </React.StrictMode>,
);
